<template>
    <el-dialog
        custom-class="custom-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>电梯名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯注册代码:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="code"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>应急状态:</span>
                    <el-select v-model="status" filterable placeholder="请选择">
                        <el-option
                        v-for="item in statusList"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey">
                        </el-option>
                    </el-select>
                </div>


                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                stripe
                ref="multipleTable"
                height="500px"
                style="width: 100%">
                    <el-table-column type="index" label="序号" width="50px" />
                    <el-table-column align='center' prop="eleName" label="电梯名称" width="200px" />
                    <el-table-column align='center' label="报警时间" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.sosTime ? scope.row.sosTime : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' label="接警时间" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.callTime ? scope.row.callTime : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' label="到场时间" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.presentTime ? scope.row.presentTime : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' label="完成时间" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.finishTime ? scope.row.finishTime : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' label="所用时长" width="200px">
                        <template slot-scope="scope">
                            {{ scope.row.useTime ? scope.row.useTime : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' label="应急状态" width="100px">
                        <template slot-scope="scope">
                            {{scope.row.status ? statusList.find(item => item.dictKey == scope.row.status).dictValue : ''}}
                        </template>
                    </el-table-column>
                    <el-table-column align='center' prop="sosTypeName" label="应急类型" width="100px" />
                    <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
						<template slot-scope="scope">
							<el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
						</template>
					</el-table-column>
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

        <rescue-detail v-if="dioDetails" :initDioDetailsItem="dioDetailsItem" :detailsItem="detailsItem" :stateList="statusList" @closeDialog="dioDetails=false" />
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import RescueDetail from './rescue-detail.vue'
export default {
    components: {
        Pagination,
        RescueDetail
    },
    props:{
        dialogTitle:String,
        start:Number|String,
        end:Number|String,
        initStatus:Number|String,
        openType:Number|String,
    },
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            code:'',
            status:this.initStatus,
            statusList:[],
            typeList:[],
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,
            //救援详情
            dioDetails:false,
            dioDetailsItem:{},
            detailsItem: {
                seleId: 1,
                seleArr: [{
                    value: 1,
                    label: '报警消息'
                }, {
                    value: 2,
                    label: '运行参数'
                }, {
                    value: 3,
                    label: '处置流程'
                }]
            },
        }
    },
    computed:{
        params(){
            return {
                current:this.current,
                size:this.size,
                start:this.start,
                end:this.end,
                eleName:this.name,
                eleCode:this.code,
                status:this.status,
            }
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog',{
                openType:this.openType
            })
        },
        reset(){
            this.name=''
            this.code=''
            this.status=this.initStatus
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },
        //获取状态字典
        async getDictionary() {
            const res=await this.$http.get(`/api/system/web/dict/dictionary?code=SOS_STATUS`)
            if(res.status==200&&res.data.code==200){
                this.statusList=res.data.data
            }
            const typeRes=await this.$http.get(`/api/system/web/dict/dictionary?code=SOS_TYPE`)
            if(typeRes.status==200&&typeRes.data.code==200){
                this.typeList=typeRes.data.data
            }
            this.getTable()
        },
        getTypeText(status){
            const list=this.typeList.filter(item=>item.dictKey==status)
            return list.length>0?list[0].dictValue:status
        },
        getStatusText(status){
            const list=this.statusList.filter(item=>item.dictKey==status)
            return list.length>0?list[0].dictValue:status
        },
        getTable(){
            this.loading=true
            this.$http.post('/api/ele/web/rescueTask/getList', this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records.map(item=>{
                        item.sosTypeName = this.getTypeText(item.sosType)
                        return item
                    })
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                }
                this.loading=false
            })
        },
        // 查看
        check(item) {
            this.$http
            .post("/api/ele/web/rescueTask/getInfo", {
                id: item.id
            })
            .then((res) => {
                if (res.data.success) {
                    res.data.data.sosVideo.forEach((qs) => {
                        let url = qs.videoUrl.split('?');
                        qs.videoUrl = url[0]
                        const urlname = url[0].split('/').pop().split('_')
                        qs.videoName = url ? urlname.length > 1 ? `${urlname[1]}_${urlname[2]}` : url[0].split('/').pop() : ''
                        // qs.videoName = url[1].split('&')[0].split('=')[1]
                        // qs.videoName = url[8]
                    })
                    res.data.data.sosTypeName = item.sosTypeName
                    res.data.data.sosStatusName = this.getStatusText(res.data.data.sosStatus)
                    res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
                    res.data.data.speedarr = res.data.data.speedRecord.split(',');

                    this.detailsItem.seleId = 1
                    this.dioDetailsItem = res.data.data
                    this.dioDetails = true

                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
    },
    created(){
        this.getDictionary()
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    // overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>