<template>
  <div class="dioBg">
    <div class="dioBg-om">
      <p>
        <span class="hpan">报警信息</span>
        <!-- <span>({{dioData.sosStatusName ? dioData.sosStatusName : (stateList.find(item => item.value == dioData.rescueTask.status) ? stateList.find(item => item.value == dioData.rescueTask.status).label : '' )}})</span> -->
      </p>
      <el-card class="infososInfo" style="height:310px">
        <span style="width: 100%;height: 50px;display: flex;justify-content: space-between; align-items: center;">
          <p class="pageHideen" :title="dioData.eleName">电梯名称: {{dioData.eleName}}</p>
          <el-button type="danger" size="mini">{{dioData.sosTypeName ? dioData.sosTypeName : '未知'}}</el-button>
        </span>
        <p style="margin-top: 0px">电梯注册代码: {{dioData.eleCode}}</p>
        <p>物联网设备号: {{dioData.deviceCode}}</p>
        <p>报警时间: {{dioData.sosTime}}</p>
        <div :class="!(adminInfo.roleId == '99') ? 'timeType':'timeTypes'" v-if="dioData.sosCount != 0">
          <p>连续报警次数：{{dioData.sosCount}}次</p>
          <p>最后一次报警时间：{{dioData.finallySosTime}}</p>
        </div>
        <!-- <p>连续报警次数：3次</p> -->
        <!-- <div style="display:flex;align-items: center;" v-if="dioData.eleSmsRescordDTOS.length > 0">
      		<span style="width:65px">短信发送:</span>
      		<div>
      			<p class="dioDataTime" v-for="(item, index) in dioData.emergencyNames" :key="index">
      				{{item}}  {{dioData.eleSmsRescordDTOS[0].createTime}}
      			</p>
      			<p class="dioDataTime" v-for="(item, index) in dioData.maintainNames" :key="index">
      				{{item}}  {{dioData.eleSmsRescordDTOS[0].createTime}}
      			</p>
      			<p class="dioDataTime" v-for="(item, index) in dioData.propertyNames" :key="index">
      				{{item}}  {{dioData.eleSmsRescordDTOS[0].createTime}}
      			</p>
      		</div>
      	</div> -->
        <p v-if="!(adminInfo.roleId == '99')">维保单位: {{dioData.maintainUnitName}}</p>
        <p v-if="!(adminInfo.roleId == '99')">物业单位: {{dioData.propertyUnitName}}</p>
        <p v-if="!(adminInfo.roleId == '99')">所属小区: {{dioData.plotName}}</p>
        <span class="pageHideen" :title="dioData.eleAddress">地点: {{dioData.eleAddress}}</span>
      </el-card>
      <p>
        <span class="hpan">电梯信息</span>
      </p>

      <el-card class="infoClass">
        <span class="msgDiv">
          <span>报警楼层: {{dioData.sosFloor}}</span>
          <span>门状态&emsp;: {{dioData.doorOpen==1?'开':'关'}}
            <span class="red" style="font-size: 14px;" v-if="dioData.sosTypeName === '困人报警'">(正常状态：开)</span></span>
        </span>
        <p class="msgDiv">
          <span>运行速度: {{dioData.speed}}m/s
            <span class="red" style="font-size: 14px;" v-if="dioData.sosTypeName === '超速报警' ||
							dioData.sosTypeName === '开门行梯' ||
							dioData.sosTypeName === '非门区停梯'">
              (正常值：&lt; {{dioData.sosTypeName === '超速报警' ? `${dioData.overSpeed}m/s` : '0.2m/s'}})
            </span>
          </span>
          <span>运行次数: {{dioData.runningNum}}</span>
        </p>
        <span class="msgDiv">
          <span> 运行方向: {{
							dioData.speed>0?'上行':
							dioData.speed<0?'下行':'静止'
						}}
          </span>
          <span>
            是否有人: {{ dioData.heads > 0 ? '有': '无' }}
            <span class="red" style="font-size: 14px;" v-if="dioData.sosTypeName === '困人报警'">(正常状态：无)</span>
          </span>
        </span>
      </el-card>
      <!-- <el-card class="infoClass">
				<span class="msgDiv">
					<span>报警楼层: {{dioData.sosFloor}}</span>
					<span>门状态&emsp;: {{dioData.doorOpen==1?'开':'关'}}</span>
				</span>
				<p class="msgDiv">
					<span>运行速度: {{dioData.speed}}m/s</span>
					<span>运行次数: {{dioData.runningNum}}</span>
				</p>
				<span class="msgDiv">
					<span>
						运行方向:
						{{
							dioData.speed>0?'上行':
							dioData.speed<0?'下行':'静止'
						}}
					</span>
				</span>
			</el-card> -->
    </div>

    <div class="dioBg-om">
      <p style="margin-top: 8px; margin-bottom: 34px; display: flex; justify-content: space-between;">
        <span class="hpan">报警视频</span>
        <el-select v-model="showUrl" @change="showVoid" placeholder="请选择" class="selectDown">
          <el-option v-for="(item, index) in dioData.sosVideo" :key="index" :label="item.videoName"
            :value="item.videoUrl">
          </el-option>
        </el-select>
      </p>
      <video v-show="showUrl" class="showVo" :src="showUrl" controls autoplay muted loop>
        <source :src="showUrl" type="video/mp4">
        您的浏览器不支持 video 标签。
      </video>
      <div style="display: flex;height: 285px;" v-show="!showUrl">
        <el-image fit="contain" :src="require(`@/assets/noVideo.png`)" style="height:220px;width:300px;margin:auto" />
      </div>
      <audio style="height: 40px;margin-top: 20px;width:100%;" v-if="dioData.sosType === 3" :src="dioData.audioFile"
        controls autoplay>
        您的浏览器不支持 audio 标签。
      </audio>

      <template v-if="sosTypeArr.includes(dioData.sosTypeName)">
        <p>
          <span class="hpan">监测信息</span>
        </p>
        <el-card class="infoClass">
          <p class="sosInfo" v-if="dioData.sosTypeName === '超速报警'">
            当前系统监测到电梯运行速度为<span class="red">{{dioData.speed}}m/s</span>,超出超速阈值
            <span class="green">{{dioData.overSpeed}}m/s</span>,触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '语音报警'">
            当前系统监测到语音报警关键词：<span class="red">{{dioData.sosText}}</span>,
            触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '开门行梯'">
            当前系统监测到电梯运行速度达<span class="red">{{dioData.speed}}m/s</span>,
            超过<span class="green">0.2m/s</span>,
            且开门时长为<span class="red">10秒</span>,
            超过<span class="green">3秒</span>,
            触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '关门异常'">
            当前系统监测到电梯处于静止状态时,开门时间达<span class="red">30秒</span>,
            超过<span class="green">20秒</span>,
            触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '困人报警'">
            当前系统监测到电梯处于静止、梯内有人、关门的状态持续达<span class="red">125秒</span>,
            超过<span class="green">90秒</span>,
            触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '非门区停梯'">
            当前系统监测到电梯运行速度为<span class="red">0m/s</span>,
            小于<span class="green">0.2 m/s</span>,
            且当前不在层站停梯区域范围内,触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '电动车识别报警'">
            当前系统监测到电梯门状态为<span class="red">开</span>，
            设备识别到<span class="red">电动车</span>,
            电梯处于<span class="red">静止</span>，触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '主动报警'">
            当前系统监测到电梯乘客主动按压SOS报警按钮,触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '反复开关门'">
            当前系统监测到电梯在30秒内连续开关门次数达<span class="red">3次</span>,
            超过<span class="green">2次</span>,
            触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '低电量报警'">
            当前系统监测到AI摄像头设备当前电量为<span class="red">15%</span>,
            低于<span class="green">20%</span>,
            触发<span class="red">{{dioData.sosTypeName}}</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '轿厢冲顶'">
            当前系统监测到电梯处于静止状态,位于<span class="red">最顶层</span>,且最近<span class="red">30秒</span>
            内出现急停现象,触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
          <p class="sosInfo" v-else-if="dioData.sosTypeName === '轿厢蹲底'">
            当前系统监测到电梯处于静止状态,位于<span class="red">最底层</span>,且最近<span class="red">30秒</span>
            内出现急停现象,触发<span class="red">{{dioData.sosTypeName}}报警</span>。
          </p>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['dioData', 'stateList'],
    data() {
      return {
        showUrl: null,
        adminInfo: JSON.parse(sessionStorage.getItem('adminInfo')),
        sosTypeArr: ['超速报警', '语音报警', '开门行梯', '关门异常', '困人报警', '非门区停梯', '电动车识别报警', '主动报警', '反复开关门', '低电量报警', '轿厢冲顶',
          '轿厢蹲底'
        ],
      }
    },
    mounted() {
      this.showUrl = this.dioData.sosVideo.length > 0 ? this.dioData.sosVideo[0].videoUrl : ''
    },
    methods: {
      showVoid(url) {
        this.showUrl = url
      }
    }
  }
</script>

<style lang="scss" scoped>
  /deep/.selectDown {
    .el-input__inner {
      height: 34px;
      line-height: 34px;
    }
  }

  .timeType {
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 0px;
  }

  .timeTypes {
    display: flex;
    justify-content: space-between;
    height: 36px;
    line-height: 0px;
  }

  /deep/.infoClass {
    margin-top: 20px;

    .el-card__body {
      padding: 15px !important;
      font-size: 15px;
      min-height: 100px;
    }
  }

  .red {
    color: #ff5454;
    font-size: 16px;
    font-weight: 600;
    margin: 0 5px;
  }

  .green {
    color: #55fb55;
    font-size: 16px;
    font-weight: 600;
    margin: 0 5px;
  }

  .showVo {
    width: 100%;
    height: 285px;
    background: #00000070;
  }

  .msgDiv {
    width: 100%;
    display: flex;
  }

  .msgDiv span {
    width: 50%;
  }

  .hpan {
    margin-right: 5px;
    font-weight: bold;
    font-size: 18px;
  }

  .dioBg-om {
    width: 48%;
    height: 10%;
    margin-right: 2%;
  }

  .dioBg-om {
    width: 48%;
    // height: 90%;
    margin-right: 2%;
  }

  .dioBg {
    width: 98%;
    height: 100%;
    display: flex;
  }
</style>
